@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/proximanova_regular_macroman/proximanova-regular-webfont.eot');
  src: url('./fonts/proximanova_regular_macroman/proximanova-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff2') format('woff2'),
    url('./fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff') format('woff'),
    url('./fonts/proximanova_regular_macroman/proximanova-regular-webfont.ttf') format('truetype'),
    url('./fonts/proximanova_regular_macroman/proximanova-regular-webfont.svg#webfont')
      format('svg');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Proxima Nova Regular', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
